import React, { useEffect } from "react"

import "./page.css"

const Page = ({
  children,
  page = "",
  background = "var(--color-html-background)",
}) => {
  useEffect(() => {
    if (background) {
      if (document) {
        document.documentElement.style.setProperty(
          "background-color",
          `${background}`
        )
      }
    }
  }, [background])

  const backgroundColor = `${background}`

  return (
    <div
      className={`page ${page}`}
      style={{ backgroundColor: backgroundColor }}
    >
      {children}
    </div>
  )
}

export default Page
